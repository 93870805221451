const homecomp = [
    {
      name: "Sri Sai Aditiya Flats  ",
      loc: "Puzhuthivakkam",
      imgURL: "./images/Completed Projects/Sri Sai Aditya.webp",
      buttonURL: "./Srisaiaditiya",
    },
    {
      name: "Thiara Enclave ",
      loc: "Madipakkam",
      imgURL: "./images/Thiara-Bageerathi-Nagar.webp",
      buttonURL: "./Thiara",
    },
    {
      name: "Sri Sai Baba Flats  ",
      loc: "S.Kolathur",
      imgURL: "./images/Completed Projects/SRI SAI BABA - S.Kolathur.webp",
      buttonURL: "./Srisaibaba",
    },
    {
      name: "Rakesh-pranav Flats ",
      loc: "Madipakkam",
      imgURL: "./images/Completed Projects/Rakesh-Pranav Flats - Periyar Nagar.webp",
      buttonURL: "./Rakeshpranav",
    },

  ];
  export default homecomp;
  