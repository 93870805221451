import React from 'react';
import Flat from '../Components/flat';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Srisairamya = () => {
  return <div>
      <Title titles="Sri Sai Ramya Flats" />
      <br />
      <Flat imgsrc="./images/Sri Sai Ramya- Annai Thersa Nagar.webp" btnurl="/Srisairamya" near1="Near Puzhuthivakkam Bus Stand" near2="Near Vyasa School" a={true} />
      <br />
<Title titles="Configuration" />
<br />
<table class="bg-white w-full container mx-auto  auto mt-5 rounded-2xl text-slate-900 border-separate shadow-none">
            <thead>
                <tr class="">
                    <th class="bg-crimson-700 rounded-tl-2xl text-slate-50 p-2">Flat No</th>
                    <th class="bg-crimson-700  text-slate-50 p-2 w-auto">Type</th>
                    <th class="bg-crimson-700 rounded-tr-2xl text-slate-50 p-2 w-1/2">Size</th>
                </tr>
            </thead>
            <tbody>
            <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">F1/S1/T1</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">850</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-slate-900">
                    <td class="p-2 ">F2/S2/T2</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">900</td>
                </tr>
               
            </tbody>
        </table>
        <br />
<Faddmap flname="Sri Sai Ramya" add1="Plot No.102," add2="Annai Teresa Nagar , 1st street" add3="Puzhuthivakkam" add4="Chennai-91"   mapurl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.0421188182827!2d80.20298777584183!3d12.969156714930365!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525dcbfaa229a9%3A0x19afbb7db6c4d9f1!2sGK%20Sri%20Sai%20Ramya!5e0!3m2!1sen!2sin!4v1686576639255!5m2!1sen!2sin" />
<br />
  </div>


};

export default Srisairamya;
