import React from 'react';
import Flat from '../Components/flat';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Srisaikubera = () => {
  return <div>
      <Title titles="Sri Sai Kubera Enclave" />
      <br />
      <Flat imgsrc="./images/Sri sai kubera- Madipakkam.webp"  near1="Near Kamatchi Hospital" near2="Near Pallikarai Marshland Park" btn={false} />
<br />
<Title titles="Configuration" />
<br />
<table class="bg-white w-full  container mx-auto mt-5 rounded-2xl text-gray-900 border-separate shadow-none">
            <thead>
                <tr class="">
                <th class="bg-crimson-700 rounded-tl-2xl text-slate-50 p-2">Flat No</th>
                    <th class="bg-crimson-700  text-slate-50 p-2 w-auto">Type</th>
                    <th class="bg-crimson-700 rounded-tr-2xl text-slate-50 p-2 w-1/2">Size</th>
                </tr>
            </thead>
            <tbody>
            <tr class="bg-slate-100 text-2xl text-center text-green-900">
                    <td class="p-2 ">A-G1/B-G1</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2">850</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-green-900">
                    <td class="p-2 rounded-bl-2xl">A-F1/B-F1</td>
                    <td class="p-2">3BHK</td>
                    <td class="p-2 rounded-br-2xl ">1458</td>
                </tr>
                <tr class="bg-slate-100 text-center text-2xl text-green-900">
                    <td class="p-2 rounded-bl-2xl">A-S1/B-S1</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2 rounded-br-2xl ">1044</td>
                </tr>
            </tbody>
        </table>
        <br />
<Faddmap add1="No.373a," add2="kuberan nagar extension,"   add3="14th street," add4="Madipakkam," add5="chennai-91" near1="Near Kamatchi Hospital"   mapurl="
https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.2645154839756!2d80.20495531509525!3d12.954918418748678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525daf8b17803d%3A0x2c83eeb5d536825b!2sGK%20Sri%20Sai%20Kubera!5e0!3m2!1sen!2sin!4v1643391342396!5m2!1sen!2sin" />
<br />
  </div>


};

export default Srisaikubera;
