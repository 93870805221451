import React, { useEffect, useState } from "react";
import { useWindowScroll } from "react-use";

import { ChevronDoubleUpIcon } from "@heroicons/react/solid";

const LgscrollToTop = () => {
  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisiblity] = useState(false);

  useEffect(() => {
    if (pageYOffset > 400) {
      setVisiblity(true);
    } else {
      setVisiblity(false);
    }
  }, [pageYOffset]);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  if (!visible) {
    return false;
  }

  return (
    <div
      className="w-full bg-slate-100 mx-auto text-center "
      onClick={scrollToTop}
    >
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 11l5-5m0 0l5 5m-5-5v12"
        />
        
      </svg> */}
  <button className="justify-center ">
      <ChevronDoubleUpIcon className="h-9 w-9 hidden lg:flex  animate-bounce hover:text-crimson-800 text-crimson-500" /></button>
    </div>
  );
};

export default LgscrollToTop;
