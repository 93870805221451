import React from "react";
import Slider from "react-slick";
import Title from "./title";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function MultipleItems() {
  var settings = {
    dots: false,
   
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay : true ,
 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false
        }
      },
    ]
  };
  return (
    <div className="bg-slate-100 rounded-xl">
      <Title titles="Banking panters" />
      <br />
      <Slider {...settings}>
        <div>
          <img
            class="mx-auto rounded-2xl w-52 "
            src="./images/hdfc.png "
            alt="hdfc "
          />
        </div>
        <div>
          <img
            class="mx-auto rounded-2xl  w-52 "
            src="./images/icici.png "
            alt="hdfc "
          />
        </div>
        <div>
          <img
            class="mx-auto rounded-2xl  w-52 "
            src="./images/lic.png "
            alt="hdfc "
          />
        </div>
        <div>
          <img
            class="mx-auto rounded-2xl  w-52 "
            src="./images/sbi.png "
            alt="hdfc "
          />
        </div>
      </Slider>
    </div>
  );
}
