import React from 'react';
import GetQuote from '../Components/getQuote';
import Featured from '../Components/Featured';
import MultipleItems from '../Components/banking';
import { Smabout } from '../Components/smabout';
import Carousel from '../Components/Banner';
import Rev from './Rev';
import Review from '../Components/Review';
import Spec from '../Components/Spec';
import Testimonials from '../Components/testimonial';
const Home = () => {
  return <div className='container mx-auto'>
        <GetQuote />
        <br />
        {/* <Carousel /> */}
        <Featured />
        <br />
      <MultipleItems />
      <br />
<Smabout />
{/* <Review /> */}
<br />
<Testimonials />
<br />
 </div>;
};

export default Home;
