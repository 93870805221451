import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Components/App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import { register } from './service-worker-registration';

const tagManagerArgs = {
  gtmId: 'GTM-M2RR25N'
}
register();

TagManager.initialize(tagManagerArgs)
ReactGA.initialize('UA-214435749-1');
ReactGA.pageview(window.location.pathname + window.location.search);
ReactDOM.render(
  <React.StrictMode>
    <div className="bg-slate-50 font-robotoc  text-slate-800"> 
    <App />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
register();