import React from 'react';
import Flat from '../Components/flat';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Srisaithiara = () => {
  return <div>
      <Title titles="Sri Sai Thiara Flats" />
      <br />
      <Flat imgsrc="./images/Sri Sai Thiara- Puzhuthivakkam.webp" btn={true} />


<Faddmap flname="Sri Sai Thiara" add1="Plot No.113," add2="27 th Street,Balaji Nagar" add3="Puzhuthivakkam" add4="Chennai-91"   mapurl="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.969685692203!2d80.20170941526035!3d12.973790718339476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d73a7c8b0f7%3A0x189c56f6ed4bac0b!2sGK%20Sri%20Sai%20Thiara%20Flats!5e0!3m2!1sen!2sin!4v1643048297605!5m2!1sen!2sin" />
<br />
  </div>


};

export default Srisaithiara;
