const currentimg = [

  {
    name: "Sri Sai Ramya ",
    loc: "Puzhuthivakkam",
    imgURL: "./images/Sri Sai Ramya- Annai Thersa Nagar.webp",
    buttonURL: "/Srisairamya",
  },
  {
    name: "Sri Sai Tharun Enclave ",
    loc: "Velachery",
    imgURL: "./images/Sri Sai Tharun - VOC Nagar.webp",
    buttonURL: "/Srisaitharun",
  },
  {
    name: " Sri Sai Kubera",
    loc: "Mapipakkam",
    imgURL: "./images/Sri sai kubera- Madipakkam.webp",
    buttonURL: "/Srisaikubera",
  },

];
export default currentimg;
