import React from "react";
import { Tabs, Tab } from "../Components/specs/Specs";
import Structure from "../Components/specs/Structure";
import Kitchen from "../Components/specs/Kitchen";
import Wall from "../Components/specs/Wall";
import Bathroom from "../Components/specs/Bathroom";
import Tiles from "../Components/specs/Tiles";
import Electrical from "../Components/specs/Electrical";
import Staircase from "../Components/specs/Staircase";
import Balcony from "../Components/specs/Balcony";
import Doors from "../Components/specs/Doors";
import Locks from "../Components/specs/Locks";
import Windows from "../Components/specs/Windows";
import Shelves from "../Components/specs/Shelves";
import Painting from "../Components/specs/Painting";
import Terrace from "../Components/specs/Terrace";
import Title from "../Components/title";
import MaterialsTab from "../Components/Spec";
import Mat from "../Components/Mat";

export default function Specification() {
  return (
    <div>
<Title titles="Specification" />, 
    {/* <Tabs> 
      <Tab component={<Structure />}><span >Structure</span></Tab>
      <Tab component={<Kitchen />}><span >Kitchen</span></Tab>
      <Tab component={<Wall />} active><span >Walls</span></Tab>
      <Tab component={<Bathroom />}><span >Bathroom</span></Tab>
      <Tab component={<Tiles />}><span >Tiles</span></Tab>
      <Tab component={<Electrical />}><span >Electricals</span></Tab>
      <Tab component={<Staircase />}><span >Staircase</span></Tab>
      <Tab component={<Balcony />}><span >Balcony</span></Tab>
      <Tab component={<Doors />}><span >Doors</span></Tab>
      <Tab component={<Locks />}><span >Locks</span></Tab>
      <Tab component={<Windows />}><span >Windows</span></Tab>
      <Tab component={<Shelves />}><span>Shelves</span></Tab>
      <Tab component={<Painting />}><span >Painting</span></Tab>
      <Tab component={<Terrace />}><span >Terrace</span></Tab>
    </Tabs> */}
    <MaterialsTab />
    <Mat />
    </div>
  );
}
