export function register() {
    if ('serviceWorker' in navigator) {
      const onsuccess = () => console.log('Service Worker registered.');
      const onfailure = () => console.log('Service Worker registration failed.');
  
      navigator.serviceWorker
        .register('custom-service-worker.js')
        .then(onsuccess)
        .catch(onfailure);
    }
  }