import React from 'react';
import Title from '../title';

const Structure = () => {
  return <div>
      <div className="flex text-center self-center"> <img src="./images/Specs/Structure.png" alt="Doors" /> <h1 className="text-center text-blue-400 text-3xl font-bold self-center">Strucuture</h1>  </div>
         <h3 className='text-blue-400 text-2xl' > Foundation </h3>
RCC Columns for foundation 
RCC plinth beam at ground Level connecting all the columns 
<h3 className='text-2xl text-blue-400'> Sill level </h3>
Concrete at 3’0” height from the Basement/ Roof
<h3 className='text-2xl text-blue-400'> Lintel </h3>
RCC Lintel at 7’0” height from the Basement/ Roof  
<h3 className='text-2xl text-blue-400'> Sunshade </h3>
1’6” wide RCC plain sunshade 
<h3 className='text-2xl text-blue-400'> Lofts </h3>
RCC Lofts at 7’0” height from the basement/ roof in kitchen and all bedrooms
          
  </div>;
};

export default Structure;

