import React, { useState } from "react";

export function Tabs({ children }) {
  function findActiveTab(a) {
    return a.reduce((accumulator, currentValue, i) => {
      if (currentValue.props.active) {
        return i;
      }

      return accumulator;
    }, 0);
  }

  function tabValidator(tab) {
    return tab.type.displayName === "Tab" ? true : false;
  }

  const [activeTab, setActiveTab] = useState(findActiveTab(children));
  return (
    <div className="flex ">
      <div className="w-1/6 flex flex-col text-center border-r-4]]">
        {children.map((item, i) => {
          return (
            <>
              {tabValidator(item) && (
                <Tab
                  key={`tab-{i}`}
                  currentTab={i}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                >
                  {item.props.children}
                </Tab>
              )}
            </>
          );
        })}
      </div>
      <div className="p-5">
        {children.map((item, i) => {
          return (
            <div className="w-full  ">
              <div className={` ${i === activeTab ? "visible" : "hidden"}`}>
                {item.props.component}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export function Tab({ children, activeTab, currentTab, setActiveTab }) {
  return (
    <>
      <div
        className={`lg:px-5 text-sm lg:text-2xl mt-2 py-3 t rounded-full cursor-pointer
      ${activeTab === currentTab ? "bg-blue-200" : "bg-blue-400  hover:bg-blue-600 text-white"}`}
        onClick={() => setActiveTab(currentTab)}
      >
        {children}
      </div>
    </>
  );
}

Tab.displayName = "Tab";
