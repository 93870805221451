import React , { useState } from "react";
import { FaArrowCircleRight, FaArrowCircleLeft } from "react-icons/fa";

const images = [
  { imgsrc: "./images/Akrthi-Padmavati-Nagar.webp", Name: "Akrthi  - Madipakkam" },
  { imgsrc: "./images/Thiara-Bageerathi-Nagar.webp", Name: "Thiara  - Madipakkam" },
  { imgsrc: "./images/Sri sai kubera- Madipakkam.webp", Name: "Sri Sai Kubera - Madipakkam" },
  { imgsrc: "/images/Sri Sai Thiara- Puzhuthivakkam.webp", Name: "Sri Sai thiara- Puzhuthivakkam" },
  { imgsrc: "/images/Sri Sai Tharun - VOC Nagar.webp", Name: "Sri Sai Tharun - VOC Nagar" },
  { imgsrc: "/images/Completed Projects/Rakesh-Pranav Flats - Periyar Nagar.webp", Name: "Rakesh-Pranav Flats - Periyar Nagar" },
  { imgsrc: "/images/Completed Projects/Sri Sai Aditya.webp", Name: "Sri Sai Aditya - Puzhuthivakkam" },
  { imgsrc: "/images/Completed Projects/SRI SAI BABA - S.Kolathur.webp", Name: "SRI SAI BABA - S.Kolathur" },
  { imgsrc: "/images/Completed Projects/Sri Sai Shanmuga.webp", Name: "Sri Sai Shanmuga - Adambakkam" },
];

export default function Gallery() {
  const [current, setCurrent] = useState(0);

  const handleNext = () => {
    setCurrent(current === images.length - 1 ? 0 : current + 1);
  };

  const handlePrev = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  };

  return (
    <div className="flex items-center justify-center flex-col">
      <div className="flex items-center justify-center mb-4">
        <button onClick={handlePrev} className="p-2 text-xl rounded">
          <FaArrowCircleLeft />
        </button>
        <div className="w-full h-auto lg:w-2/4 flex justify-center ">
          <img src={images[current].imgsrc} alt="Slide" className="object-scale-down rounded-xl"/>
        </div>
        <button onClick={handleNext} className="p-2 text-xl rounded">
          <FaArrowCircleRight />
        </button>
      </div>
      <div className="text-xl">
        <p > {images[current].Name}</p>
      </div>
    </div>
  );
}
