import React from 'react';

const TeamSection = () => {
  const teamMembers = [
    {
      name: 'K.K.Kumaran',
      title: 'Founder and Partner - GK Sai Homes ',
      imgSrc: './images/Kumaran.webp',
    },
    {
      name: 'S.B.Ganesh',
      title: 'Founder and Parnter- GK Promoters',
      imgSrc: './images/Ganesh.webp',
    },
    
    {
      name: 'S.V.Yogesh',
      title: 'Partner',
      imgSrc: './images/Yogesh.webp',
    },
    {
      name: 'S.G.Srinivas',
      title: 'Partner',
      imgSrc: './images/Srinivas.webp',
    },
    // Add more team members here
  ];

  return (
    <div className="container mx-auto bg-crimson-50 px-4 py-16">
      <h2 className="text-4xl font-bold text-center mb-8">Our Team</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {teamMembers.map((member, index) => (
          <div key={index} className="text-center">
            <img
              src={member.imgSrc}
              alt={member.name}
              className="mx-auto rounded-full w-32 h-32 mb-4"
            />
            <h3 className="text-xl font-semibold mb-2">{member.name}</h3>
            <p className="text-gray-600 mb-4">{member.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamSection;