import React from 'react';
import {Link} from "react-router-dom"
import { GiCctvCamera } from "react-icons/gi";
import { GiPartyHat } from "react-icons/gi";
import { GiElevator } from "react-icons/gi";
import { GiWaterTank } from "react-icons/gi";
import { GiPowerGenerator } from "react-icons/gi";
import { FaCar,FaFingerprint } from "react-icons/fa";
import { GiSecurityGate } from "react-icons/gi";
import {ReactComponent as Carparking} from "./parking.svg"
import { IoFingerPrintSharp,  } from "react-icons/io";
import { BiRightArrow } from "react-icons/bi";


const Preflat = ({imgsrc,near1,near2,btn}) => {

  return <div>
 <div className="flex container mx-auto flex-wrap">
            <img className="rounded-2xl px-2 w-full lg:w-2/4 " src={imgsrc} alt="flat image"></img>
            <div className="text-sm lg:text-2xl lg:w-1/4  font-bold ">
            <div className='flex ml-2'>
            <div className='w-1/2'>
            <h3 className="flex items-center text-xl"><FaCar className="mr-2"/>Covered car parking</h3>
            <h3 className="flex items-center text-xl"><GiElevator className="mr-2"/>Lift facility</h3>
            <h3 className="flex items-center text-xl"><GiPowerGenerator className="mr-2"/>Power Backup</h3>

          </div>
          <div className="w-1/2">
            <h3 className="flex items-center text-xl"><GiCctvCamera className="mr-2"/>CCTV Coverage</h3>
            <h3 className="flex items-center text-xl"><GiPartyHat className="mr-2"/>Party Hall</h3>
            <h3 className="flex items-center text-xl"><FaFingerprint className="mr-2"/>Biometric lock</h3>
          </div>
</div>

                <span className="text-xl lg:text-2xl font-extrabold  ">   {near1} <br /> {near2} </span> <br />
             { !btn  &&  <Link to="/Contact" > <button class="py-auto  bg-crimson-600  hover:bg-blue-800  text-white text-lg font-bold py-2 px-4 rounded-lg ">
                        Get Quote
                    </button></Link>  }
                    </div>
                    <div>
                    <div class=" bg-slate-300 rounded-2xl mt-7 ">
                      </div>

                      </div>
                      </div>

         
                  
            
        
  </div>;
};

export default Preflat;
