import React from 'react';
import { FaHome, FaPhone, FaEnvelope } from "react-icons/fa";
import Title from './title';
import ContactUs from '../Components/Form';
import { HomeIcon } from "@heroicons/react/solid";
import { PhoneIcon } from "@heroicons/react/solid";
import { MailOpenIcon } from "@heroicons/react/solid";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter} from "react-icons/fa";
const Faddmap = ({flname,add1,add2,add3,add4,add5,mapurl}) => {
  
  return     <div>
   <Title titles="Address and location " />
   <br />
   <div className=" rounded-2xl container mx-auto  ">
 
  <div className=" flex flex-wrap">

    <div className="w-full lg:w-1/2 border-4 border-gray-800 rounded-2xl">
      <div className="">
        <h1 className="text-2xl   text-center font-extrabold  border-gray-400 text-gray-800"> Address </h1>
         <br />
      </div>
 
      <div className=" ">
        
           
              <p className="flex text-xl items-center justify-start px-10 lg:px-40 mt-2 mb-2 mx-2 ">
                <HomeIcon className="h-8 w-8 " />
                <p className="lg:text-xl text-left mx-2  "> {add1}<br />{add2}<br />{add3} <br />{add4} <br />{add5} </p>
              </p>
              <p className="flex text-xl items-center px-10 lg:px-40   mb-4 mx-2 ">
            
                <MailOpenIcon className="w-7 h-7" /><a className="" href="mailto:info@gksaihomes.com ">
                   <span className="lg:text-xl text-left mx-2  "> info@gksaihomes.com</span></a>
                
              </p>
              <p className="flex text-xl items-center px-10 lg:px-40 mx-2 mb-4   ">
             <PhoneIcon className="h-8 w-8"/>           
           <a href="tel:+919841167185 ">  <span className="lg:text-xl text-left mx-2  ">+91-9841167185 </span> </a>
           <a href="tel:+919791028585 ">  <span className="lg:text-xl text-left mx-2  ">| +91-9791028585</span> </a>
              </p>
              <p className="flex text- items-center px-10 lg:px-40 mt mb-4 mx-2md:justify-start  ">
                <a href="https://www.facebook.com/profile.php?id=100094771590910&mibextid=ZbWKwL " className="mr-6 text-blue-600 hover:text-blue-800">
                  
                  <FaFacebookF className="h-10 w-8  " />
                </a>
                <a href="https://twitter.com/promoters_gk " className="mr-6 text-blue-400 hover:text-blue-600 ">
                   
                <FaTwitter className="h-11 w-11" />
                </a>
                <a href="https://www.instagram.com/gksai_homes/?r=nametag " className="mr-6 text-purple-500 hover:text-purple-700  ">
           
                   <FaInstagram className="h-11 w-11" />
                </a>
              </p>
            </div>
            <div className="p-5 mt-6  text-left w-full md:w-1/4 ">
            </div>
      
     </div>
    <div className="w-full lg:w-1/2 bg-blue-300  rounded-2xl">
      <div ><iframe className="rounded-2xl" width="100%" height={425} frameBorder={0} scrolling="no" marginHeight={0} marginWidth={0} src={mapurl}>
        </iframe></div>
       
    </div>
  </div>
</div>
</div>;
};

export default Faddmap;
