import React from "react";
import {Link} from "react-router-dom"
function Button({ buttonrul , buttondis }) {
  return (
    <div>
      <Link to={buttonrul}><button
                            class=" py-auto  bg-blue-500  hover:bg-blue-800  text-white text-lg font-bold py-2 px-4 rounded-full ">
                            {buttondis}
                        </button>
                    </Link>
    </div>
  );
}

export default Button;
