import React from "react";
import Title from "./title";
import Current from "./current";
import currentimg from "./currentimg";
import homecomp from "./homecomp";
function Featured() {
  return (
    <div class=" text-xl text-gray-800 rounded-2xl">
      <Title titles="Completed Projects " />
      <div className="flex flex-wrap  ">
      {homecomp &&
          homecomp.map((current) => (
            <Current
              name={current.name}
              img={current.imgURL}
              loc={current.loc}
              button={current.buttonURL}
            />
          ))}
      </div>
    </div>
  );
}
export default Featured;
