import React from "react";
function Currentpjsx({ name, img, loc, button }) {
  return (
    <div className="w-full mt-2 overflow-hidden lg:w-1/3 lg:px-3">
      <div className="bg-white rounded-2xl ">
        <img className="rounded-xl" src={img} alt="flat img" />
        <p className="font-bold text-xl text-center text-slate-900 ">
          {name} <br /> {loc}
        </p>
        <div className="text-center mt-4 ">
          <a href={button}>
            <button className="bg-crimson-500 hover:bg-crimson-700 text-slate-200 text-xl px-3 py-2 rounded-lg ">
              Details
            </button>
          </a>

          <div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Currentpjsx;
