import React from 'react';
import  Preflats from '../Components/Preflats';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
import Contact from './Contact';
import Specification from './Specification';
const Landing = () => {
  return <div>
    <Title titles=" Thiara Flats" />
      <Preflats imgsrc="./images/landing/Thiara landing.jpeg" btnurl="/Srisaithiara" near1="Near Hotel Nakshtra" near2="Near Pon Vidhyasram School" btn={true} />

<Title titles="configuration" />
<table class="bg-white w-full   auto mt-5 rounded-2xl text-gray-900 border-separate shadow-none">
            <thead>
                <tr class="">
                    <th class="bg-blue-700 rounded-tl-2xl text-white p-2">Flat No</th>
                    <th class="bg-blue-700  text-white p-2 w-auto">Type</th>
                    <th class="bg-blue-700 rounded-tr-2xl text-white p-2 w-1/2">Size</th>
                </tr>
            </thead>
            <tbody>
            <tr class="bg-gray-100 text-center text-2xl text-green-900">
                    <td class="p-2 ">F1/S1/T1</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">872</td>
                </tr>
                <tr class="bg-gray-100 text-center text-2xl text-green-900">
                    <td class="p-2 ">F2/S2/T2</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2  ">879</td>
                </tr>
                <tr class="bg-gray-100 text-2xl text-center text-green-900">
                    <td class="p-2">F3/S3/T3</td>
                    <td class="p-2">2BHK</td>
                    <td class="p-2">881</td>
                </tr>
                <tr class="bg-gray-100 text-center text-2xl text-green-900">
                    <td class="p-2">F4/S4/T4</td>
                    <td class="p-2">3BHK</td>
                    <td class="p-2">1152</td>
                </tr>
                <tr class="bg-gray-100 text-center text-2xl text-green-900">
                    <td class="p-2 rounded-bl-2xl">F5/S5/T5</td>
                    <td class="p-2">3BHK</td>
                    <td class="p-2 rounded-br-2xl ">1156</td>
                </tr>
            </tbody>
        </table>

<Faddmap add1="Plot No.82 &Amp; 83," add2="Vallal Adhiyaman Street,"   add3="Bageerathi Nagar" add4="Madipakkam" add5="Chennai-91" near1="Near Pon Vidhyasram School"  mapurl="
https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.110926933467!2d80.21135151526032!3d12.964753118535379!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525d4d0f600555%3A0xe8c1e8be9602e9a7!2sThiara%20Flats!5e0!3m2!1sen!2sin!4v1643048359550!5m2!1sen!2sin" />
 


<Specification />
<Contact />
 
 
 </div>
};

export default Landing;
