import React from "react";
import compimg from "./Compimg";
function Completedpjsx({ name, img, loc, button }) {
  return (
    <div className="w-full mt-2 overflow-hidden lg:w-1/3 lg:px-3">
      <div className="bg-white rounded-2xl ">
        <img className="rounded-xl" src={img} alt={name} />
        <p className="font-bold text-xl text-center text-gray-800 ">
          {name} <br /> {loc}
        </p>
        <div className="text-center mt-4 ">
          <a href={button}>
            <button className="py-auto  bg-crimson-500  hover:bg-crimson-800  text-slate-50 text-lg font-bold py-2 px-4 rounded-lg ">
              Details
            </button>
          </a>

          <div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Completedpjsx;
