import React from 'react';
import Flat from '../Components/flat';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Thanshikaa = () => {
  return <div>
      <Title titles="Thanshikaa Flats" />
      <br />
      <Flat imgsrc="./images/Completed Projects/Thanshikaa.webp" btn={false}  />

<Faddmap add1="Plot No.1A," add2="Bhajani Kovil Street," add3="Rajarajeswari Nagar"  add4="Madipakkam" add5="chennai-91"  mapurl="
https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.657007887394!2d80.1970301!3d12.9613394!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x51c1bdc2126e3b4a!2sGK%20Thanshikaa%20Flats!5e0!3m2!1sen!2sin!4v1643047273638!5m2!1sen!2sin" />
  <br />
  </div>


};

export default Thanshikaa;
