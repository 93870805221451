import React from 'react'
import AboutUsTabs from '../Components/AboutUsTabs'
import TeamSection from '../Components/team'
const About = () => {
  return (
    <div>
      <AboutUsTabs />
      <TeamSection />
    </div>
  )
}

export default About