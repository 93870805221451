import React from "react";
import Title from "../Components/title";
import compimg from "../Components/Compimg";
import Completedpjsx from "../Components/Completedp";
function CompletedProjects() {
  return (
    <div class=" text-xl container mx-auto text-gray-800 rounded-2xl">
      <Title titles="Completed projects" />
      <div className="flex flex-wrap ">
        {compimg &&
          compimg.map((Completedp) => (
            <Completedpjsx
              name={Completedp.name}
              img={Completedp.imgURL}
              loc={Completedp.loc}
              button={Completedp.buttonURL}
            />
            
          ))
          } 
        
      </div>
   
    </div>
  );
}
export default CompletedProjects;
