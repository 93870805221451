import React from 'react';
import Flat from '../Components/flat';
import Title from '../Components/title';
import Faddmap from '../Components/faddmap';
const Srisaibaba = () => {
  return <div>
      <Title titles="Sri Sai Baba Flats" />
      <br />
      <Flat imgsrc="./images/Completed Projects/SRI SAI BABA - S.Kolathur.webp" btn={true}  />


<Faddmap add1="plot No.228," add2="1st Street," add3="Bagiyalakshmi Nagar,"  add4="S.Kolathur" add5="chennai-100"  mapurl="
https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.33485232877!2d80.19499500075568!3d12.950412105343949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525da60b000bb9%3A0xdd1de6545954a1fc!2sPlot%20number%20228%2C%20Sri%20Sai%20Baba%20Flats%2C%201st%20St%2C%20S.%20Kolathur%2C%20S.Kolathur%2C%20Bagyalakshmi%20Nagar%2C%20Kovilambakkam%2C%20Chennai%2C%20Tamil%20Nadu%20600117!5e0!3m2!1sen!2sin!4v1643092257325!5m2!1sen!2sin" />
  <br />
  </div>


};

export default Srisaibaba;
