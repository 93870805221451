import { useEffect, useState } from 'react';

// Custom component for Next and Previous buttons
function TabButtons({ onPrevious, onNext }) {
  return (
    <div className="absolute hidden lg:flex top-4 right-4">
      <button className="bg-crimson-600 text-slate-50 px-2 py-1 rounded mr-2" onClick={onPrevious}>
        Previous
      </button>
      <button className="bg-crimson-600 text-slate-50 px-2 py-1 rounded" onClick={onNext}>
        Next
      </button>
    </div>
  );
}

function Mat() {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    changeTab(0);
  }, []);

  const changeTab = (index) => {
    setActiveTab(index);
  };

  const previousTab = () => {
    const newIndex = activeTab === 0 ? tabs.length - 1 : activeTab - 1;
    changeTab(newIndex);
  };

  const nextTab = () => {
    const newIndex = activeTab === tabs.length - 1 ? 0 : activeTab + 1;
    changeTab(newIndex);
  };

  const tabs = [
    {
      title: 'Structure',
      content: [
        { heading: 'Foundation', text: 'RCC Columns for foundation' },
        { heading: 'Sill level', text: 'Concrete at 3’0” height from the Basement/ Roof' },
        { heading: 'Lintels', text: 'RCC Lintel at 7’0” height from the Basement/ Roof' },
        { heading: 'Sunshades', text: '1’6” wide RCC plain sunshade' },
        { heading: 'Lofts', text: 'RCC Lofts at 7’0” height from the basement/ roof in kitchen and all bedrooms' }
      ]
    },
    {
      title: 'Kitchen',
      content: [
        { heading: 'Platform', text: 'Platform with granite slab of 600 mm wide at height of 800mm from the floor level' },
        { heading: 'Sink', text: 'Single bowl QUARTZ sink' },
        { heading: 'Drain', text: 'Pest free square designer Stainless steel grating' },
        { heading: 'Dining', text: 'Wall mount washbasin wherever applicable' }
      ]
    },
    {
      title: 'Walls',
      content: [
        { heading: 'Internal', text: '4½” Brick wall' },
        { heading: 'External', text: '9" Brick wall' },
        { heading: 'Parapet', text: '4½" Brick wall at 3\' height' }
      
      ]
    },
    {
      title: 'Bathroom',
      content: [
        { heading: 'Closet', text: 'Floor mount European Water Closet with cistern and health faucet' },
        { heading: 'Shower', text: 'Single lever concealed diverter with overhead shower and arm' },
        { heading: 'Wash Basin', text: 'Wall mount washbasin' },
        { heading: 'Drain', text: 'Pest free Square/ Round designer Stainless steel Grating' },
      ],
    },
    {
      title: 'Tiles',
      content: [
        { heading: 'Living floor', text: 'High gloss vitrified tiles of size 800x800 mm' },
        { heading: 'Kitchen & dining floor', text: 'Matte finish vitrified tiles of 800x800 mm' },
        { heading: 'Kitchen wall', text: 'Glossy vitrified tiles of 450x300 mm' },
        { heading: 'Bedroom floor', text: 'High gloss vitrified tiles of size 800x800 mm' },
        { heading: 'Bathroom floor', text: 'Anti-skid ceramic tiles of 300x300 mm' },
        { heading: 'Bathroom wall', text: 'Glossy ceramic tiles of 450x300 mm' },
        { heading: 'Balcony', text: 'Anti-skid ceramic tiles of 300x300 mm' },
      ],
    },
    {
      title: 'Electricals',
      content: [
        { heading: 'Supply', text: '3Phase power supply' },
        { heading: 'Safety Devices', text: 'MCB, ELCB' },
        { heading: 'EV Charging', text: 'Electric Vehicle charging pod in stilt area' },
        { heading: 'Switches and sockets', text: 'Modular box, modular switches and sockets' },
        { heading: 'Wires', text: 'Fire Retardant Low Smoke (FRLS) copper wires of quality IS brand' },
        { heading: 'Spot Light', text: '4 Spot light provision in Hall' },
        { heading: 'TV Point', text: 'One point in Hall and Master bedroom' },
        { heading: 'Split air conditioner', text: 'One AC point in Hall and all bedrooms' },
        { heading: 'Exhaust fan', text: 'One point in Kitchen and all bathrooms' },
        { heading: 'Geyser', text: 'One point in all bathrooms' },
        { heading: 'Inverter', text: 'Inverter provision will be given for each homes' },
        { heading: 'CCTV', text: 'Four CCTV cameras will be provided in Stilt floor' },
      ],
    },
    {
      title: 'Staircase',
      content: [
        { heading: 'Steps', text: 'RCC Slab with brick steps' },
        { heading: 'Handrail', text: 'MS Grill handrail' },
      ],
    },
    {
      title: 'Balcony',
      content: [
        { text: 'MS Handrail as per design' },
      ],
  
    },
    {
      title: 'Locks',
      content: [
        { text: 'Digital locks in Main door' },
        { text: 'Tower bolts, door viewer, safety latch, door stopper, door closure in Stainless steel/ Matte finish' },
      ],
  
    },
    {
      title: 'Doors',
      content: [
        { heading: 'Main Doors', text: 'Door of size 3’6”x7’0” of good quality teakwood frame of size 5”x3” and shutters of laminated / Teakwood Veneer finish' },
        { heading: 'Bedroom Doors', text: 'Good quality door frame of size 4”x2’6” with American skin door of size 3’0”x7’0”' },
        { heading: 'Bathroom Doors', text: 'Good quality door frame of size 3”x2” with FRP moulded shutters of size 2’6”x7’0”' },
      ],
  
    },
    {
      title: 'Lift',
      content: [
        { text: '5 Passenger lift upto terrace' },
      ],
  
    },
    {
      title: 'Shelves',
      content: [
        { heading: 'Bedroom', text: 'Good quality door frame of size 3”x2” with FRP moulded shutters of size 2’6”x7’0”' },
        { heading: 'Kitchen', text: 'Storage unit with Cudappa slabs' },
        { heading: 'Pooja', text: 'Pooja unit with Cudappa slabs' },
      ],
    },
    {
      title: 'Windows',
      content: [
        { heading: 'Hall and bedroom', text: 'UPVC windows with openable shutters with plain glass and MS grill on the inner side' },
        { heading: 'Kitchen', text: 'UPVC windows with sliding shutters with plain glass and MS grill on the inner side' },
        { heading: 'French window', text: 'UPVC French window with sliding shutters with plain glass and without grills' },
        { heading: 'Ventilator', text: 'UPVC frame of fixed or adjustable louvers with exhaust fan provision' },
      ],
    },
    {
      title: 'Painting',
      content: [
        { heading: 'Internal', text: '2 coats of putty, 1 coat primer and 2 coat of Emulsion paint' },
        { heading: 'Ceiling', text: '1 coat of primer and 2 coats of Ace paint' },
        { heading: 'External', text: '1 coat of primer and 2 coats of Ace paint' },
      ],
    },
    {
      title: 'Terrace',
      content: [
        { text: '10”x10” White color anti-skid heat reflecting tiles' },
      ],
    }
  ];

  return (
    <div className="container mx-auto  border border-crimson-600 rounded mt-10">
   <div className="w-full text-center bg-crimson-600 text-slate-50 py-2 rounded-t">
        <h2 className="">Quality of Materials used in our Construction</h2>
      </div>
      <div className="relative flex flex-wrap mt-5">
        <div className="w-1/2 lg:w-1/4 border-r-2 border-crimson-600">
          <div>
            <ul className="space-y-2">
              {tabs.map((tab, index) => (
                <li
                  key={index}
                  className={`cursor-pointer text-black text-xl px-2 py-1 text-center rounded ${
                    activeTab === index ? 'active bg-crimson-200' : ''
                  }`}
                  onClick={() => changeTab(index)}
                >
                  {tab.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-1/2 lg:w-3/4 px-5 lg:h-auto h-[700px] overflow-y-auto">
          {tabs.map((tab, index) => (
            <div
              key={index}
              id={`group2-tab-${index}`}
              className={`tab-content group2 ${activeTab === index ? '' : 'hidden'}`}
            >
              <h3 className="text-xl font-bold justify-between items-center mb-4">{tab.title}</h3>
              {index === tabs.length - 1 && (
                <TabButtons
                  onPrevious={previousTab}
                  onNext={() => changeTab(0)}
                />
              )}
              {index === 0 && (
                <TabButtons
                  onPrevious={() => changeTab(tabs.length - 1)}
                  onNext={nextTab}
                />
              )}
              {index > 0 && index < tabs.length - 1 && (
                <TabButtons
                  onPrevious={previousTab}
                  onNext={nextTab}
                />
              )}
              <div className="flex flex-wrap -mx-4">
                {tab.content.map((item, idx) => (
                  <div key={idx} className="w-full md:w-1/2 px-4 text-left">
                    <p className="font-bold">{item.heading}</p>
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Mat;
