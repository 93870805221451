import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Title from './title';
import { FaStar, FaRegStar, FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';

const testimonials = [
  {
    img: "/images/Testimonials/Manoj.png",
    name: "Manoj S",
    content: "GK Promoters, builders we came across through mere surfing but were sp impressed on speaking to them that we decided to buy our property from them. They are very genuine in their work, patient to listen us, efficient skills, commitment to the task and most importantly customer centric. They keep up their promises and help you in making your dream house as you wish. Special thanks to Yogesh who had been very patient and kind to all our queries.",
    stars: 5
  },
  {
    img: "/images/Testimonials/Arulmouzhi.png",
    name: "Arulmouzhi Ezhilarasan",
    content: "I'm glad with my decision to buy a flat from GK Promoters. They are doing a really good job. We went with our needs & they helped us with good recommendations. Highly appreciated for hearing out each and every requirement from customers. They are also helping through the loan option and interiors as well. Booked the ideal apartment for our needs. Moreover, I experienced professionalism throughout my journey with them. Special thanks to Yogesh for all his help!",
    stars: 5
  },
  {
    img: "/images/Testimonials/Prasanth Anand.png",
    name: "Prasanth Anand",
    content: "Having spoken with several builders in the past, compared to them, gk promoters were transparent with their pricing. Patiently listened to my requirements and gave me straightforward answers about cost and delivery time. I ended up booking an apartment with them and will update once again when the handover is done. 🤞",
    stars: 4
  },
  {
    img: "/images/Testimonials/Ganesh kumar.png",
    name: "Ganesh Kumar",
    content: "On time, responsible and polite approach, quality is top notch considering price compared to others in the market now. friendly approach, flexible to request. Highly recommend.",
    stars: 5
  },
  {
    img: "/images/Testimonials/Santhosh Balaji.png",
    name: "Santhosh Balaji Raveendran",
    content: "builders with good experience and better pricing. Was given with more options for apartment in different locations.",
    stars: 5
  },
  {
    img: "/images/Testimonials/Vijay Shankar.png",
    name: "Vijay Shankar",
    content: "prominent promoters in Madipakkam area , provided seamless service.",
    stars: 5
  },
  // ... rest of the testimonials
];

// Custom arrow components for the slider
function NextArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-next" onClick={onClick}>
      <FaArrowCircleRight className="text-blue-600 hidden lg:flex" size={30} />
    </div>
  );
}

function PrevArrow(props) {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-prev  " onClick={onClick}>
      <FaArrowCircleLeft className=" hidden lg:flex" size={30} />
    </div>
  );
}

export default function Testimonial() {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay : true ,
 
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: false,
        },
      },
    ]
  };

  return (
<div>
<Title titles="Our Testimonial" />
    <Slider {...settings}>
      {testimonials.map((testimonial, idx) => (
        <div key={idx} className="p-4 flex flex-wrap container mx-auto  bg-bacol-50  shadow-md rounded-lg  my-2">
        <div className='flex'>
        
          <img className="w-auto rounded-full m-4" src={testimonial.img} alt={testimonial.name}/>
        
          <h2 className="mt-4 text-lg font-semibold text-blue-600">{testimonial.name}</h2></div>
          <div className="flex">
            {[...Array(5)].map((e, i) => 
              i < testimonial.stars 
                ? <FaStar className="text-yellow-400" key={i}/> 
                : <FaRegStar key={i}/>
            )}
          </div>
          <p className="text-black">{testimonial.content}</p>
   
        </div>
      ))}
    </Slider>
    </div>
  );
}
