import React from 'react';
import Title from './title';
import Button from './button';
import {Link} from "react-router-dom"
export const Smabout = () => {
  return  <div className="container mt-10 rounded-lg  mx-auto bg-slate-100">
  <h1 className="text-center text-3xl font-bold ">Why Choose Us </h1>
  <div className="flex flex-wrap justify-center p-2">
    <div className="text-center mx-4 my-8 w-full lg:w-1/4">
      <img
        src="./images/smabout/trust.png"
        alt="Experince"
        className="mx-auto mb-4"
      />
      <h3 className="font-semibold">Experience and Trust</h3>
      <p className="text-base font-light leading-6">
      Having been in the industry for more than twenty years, our expertise serves as the foundation for our creative and customized strategies, which guarantee the success to our clients.
      </p>
    </div>
    <div className="text-center mx-4 my-8 w-full lg:w-1/4">
      <img
        src="./images/smabout/team.png"
        alt="Team"
        className="mx-auto mb-4"
      />
      <h3 className="font-semibold">Highly Qualified  &amp; Experienced Team</h3>
      <p className="text-base font-light leading-6">
      we have a team of professionals who are industry experts and possess extensive knowledge and experience in their specific fields. We keep ourselves informed about the latest trends and technologies to deliver advanced solutions that meet your requirements.      </p>
    </div>
    <div className="text-center mx-4 my-8 w-full lg:w-1/4">
      <img
        src="./images/smabout/quality.png"
        alt="Product"
        className="mx-auto mb-4"
      />
      <h3 className="font-semibold">
      Quality Products &amp;  In-House Manufacturing
      </h3>
      <p className="text-base font-light leading-6">
      Our dedication is to provide top-notch products and services. We have strict quality assurance procedures that guarantee that all deliverables meet the highest standards and surpass your expectations.
      </p>
    </div>
    <div className="text-center mx-4 my-8 w-full lg:w-1/4">
      <img
        src="./images/smabout/personal.png"
        alt="Personalized "
        className="mx-auto mb-4"
      />
      <h3 className="font-semibold">Personalized Approach</h3>
      <p className="text-base font-light leading-6">
      We hold the belief that every client has distinctive needs, and we acknowledge that. Therefore, we invest time in comprehending your particular requirements to provide tailor-made solutions that efficiently and effectively tackle your challenges.
      </p>
    </div>
    <div className="text-center mx-4 my-8 w-full lg:w-1/4">
      <img
        src="./images/smabout/punctuality.png"
        alt="Punctuality"
        className="mx-auto mb-4"
      />
      <h3 className="font-semibold">Punctuality  &amp; Value for Money</h3>
      <p className="text-base font-light leading-6">
      Your time and timelines are important to us. Our effective project management practices and simplified processes allow us to achieve our objectives.What's more, we offer competitive prices that ensure you get the best value for your investment.
      </p>
    </div>
    <div className="text-center mx-4 my-8 w-full lg:w-1/4">
      <img
        src="./images/smabout/client.png"
        alt="Satisfaction"
        className="mx-auto mb-4"
      />
      <h3 className="font-semibold">Client Satisfaction</h3>
      <p className="text-base font-light leading-6">
      Our topmost objective at GK Promoters is your satisfaction. We gauge our accomplishments based on the positive feedback and referrals from our clients. We make every effort to ensure that every interaction with our company is a pleasant experience.
      </p>
    </div>
  </div>
</div>

};
