import React from "react";
import Title from "../Components/title";
import currentimg from "../Components/currentimg";
import Currentpjsx from "../Components/Currentp";
function CurrentProject() {
  return (
    <div class=" text-xl container mx-auto rounded-2xl">
      <Title titles="Current projects" />
      <div className="flex flex-wrap ">
        {currentimg &&
          currentimg.map((Currentp) => (
            <Currentpjsx
              name={Currentp.name}
              img={Currentp.imgURL}
              loc={Currentp.loc}
              button={Currentp.buttonURL}
            />
            
          ))
          } 
        
      </div>
    </div>
  );
}
export default CurrentProject;
