import React from "react";
function Title({ titles }) {
  return (
    <div>
      <h1 className=" text-center text-xl lg:text-2xl font-bold  justify-center">
        {titles}
      </h1>
    </div>
  );
}

export default Title;
