import React, { useState } from 'react';

const brandsData = [
  {
    name: 'Cement',
    brands: [
      { imgSrc: '/images/Brands used/Chettinad Cement.png', imgAlt: 'Chettinad Cement' },
      { imgSrc: '/images/Brands used/Ultratech cement.png', imgAlt: 'Ultratech Cement' },
      { imgSrc: '/images/Brands used/Zuari Cement.png', imgAlt: 'Zuari Cement' },
    ],
  },
  {
    name: 'Steel',
    brands: [
      { imgSrc: '/images/Brands used/JSW Steel.png', imgAlt: 'JSW Steel' },
      { imgSrc: '/images/Brands used/Thirumala Steel.png', imgAlt: 'Thirumala Steel' },
      { imgSrc: '/images/Brands used/Isteel .png', imgAlt: 'Isteel' },
    ],
  },
  {
    name: 'Tiles',
    brands: [
      { imgSrc: '/images/Brands used/Kajaria Tiles.png', imgAlt: 'Kajaria Tiles' },
      { imgSrc: '/images/Brands used/Johansan tiles.png', imgAlt: 'Johansan Tiles' },
      { imgSrc: '/images/Brands used/Exxaro TIles.png', imgAlt: 'Exxaro Tiles' },
    ],
  },
  {
    name: 'Plumbing Pipes',
    brands: [
      { imgSrc: '/images/Brands used/Ashirvad Pipes.png', imgAlt: 'Ashirvad Pipes' },
      { imgSrc: '/images/Brands used/Astral pipes .png', imgAlt: 'Astral Pipes' },
    ],
  },
  {
    name: 'Plumbing Fittings',
    brands: [
      { imgSrc: '/images/Brands used/Kerovit fittings.png', imgAlt: 'Kerovit Fittings' },
      { imgSrc: '/images/Brands used/parryware fittings.png', imgAlt: 'Parryware Fittings' },
      { imgSrc: '/images/Brands used/kohler fittings.png', imgAlt: 'Kohler Fittings' },
    ],
  },
  {
    name: 'Wires',
    brands: [
      { imgSrc: '/images/Brands used/Anchor Wires.png', imgAlt: 'Anchor Wires' },
      { imgSrc: '/images/Brands used/Havells wires.png', imgAlt: 'Havells Wires' },
    ],
  },
  {
    name: 'Switches',
    brands: [
      { imgSrc: '/images/Brands used/Legrand Switches.png', imgAlt: 'Legrand Switches' },
      { imgSrc: '/images/Brands used/Havells switches.png', imgAlt: 'Havells Switches' },
    ],
  },
  {
    name: 'Door Locks',
    brands: [
      { imgSrc: '/images/Brands used/Dorset Door Locks.png', imgAlt: 'Dorset Door Locks' },
      { imgSrc: '/images/Brands used/Yale Door Locks.png', imgAlt: 'Yale Door Locks' },
      { imgSrc: '/images/Brands used/Europa Door Locks.png', imgAlt: 'Europa Door Locks' },
    ],
  },
  {
    name: 'Lift',
    brands: [
      { imgSrc: '/images/Brands used/Johnson Lifts.png', imgAlt: 'Johnson Lifts' },
      { imgSrc: '/images/Brands used/OTIS lifts.png', imgAlt: 'OTIS Lifts' },
    ],
  },
  {
    name: 'Kitchen Sink',
    brands: [
      { imgSrc: '/images/Brands used/CARYSIL Sinks.png', imgAlt: 'CARYSIL Sinks' },
      { imgSrc: '/images/Brands used/Franke Sinks.png', imgAlt: 'Franke Sinks' },
    ],
    
  },
  {
    name: 'Readymix Concrete',
    brands: [
      { imgSrc: '/images/Brands used/Sunx RMC.png', imgAlt: 'Sunx RMC' },
      { imgSrc: '/images/Brands used/AAC RMC.png', imgAlt: 'AAC RMC' },
    ],
  },
  // Add more materials...
];

const PreviousButton = ({ onClick }) => (
  <button className="bg-crimson-600 text-slate-50 px-2 py-1 rounded mr-2" onClick={onClick}>
    Previous
  </button>
);

const NextButton = ({ onClick }) => (
  <button className="bg-crimson-600 text-slate-50 px-2 py-1 rounded" onClick={onClick}>
    Next
  </button>
);

const MaterialsTab = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const handlePreviousClick = () => {
    setActiveTab((prevTab) => (prevTab === 0 ? brandsData.length - 1 : prevTab - 1));
  };

  const handleNextClick = () => {
    setActiveTab((prevTab) => (prevTab === brandsData.length - 1 ? 0 : prevTab + 1));
  };

  return (
    <div className="container mx-auto border border-crimson-600 rounded">
      <div className="w-full text-center bg-crimson-600 text-slate-50 py-2 rounded-t">
        <h2 className="">Brand of Materials used in our Construction</h2>
      </div>
      <div className="relative flex flex-wrap mt-5">
        <div className="w-1/2 lg:w-1/4 border-r-2 border-crimson-600">
          <div>
            <ul className="space-y-2">
              {brandsData.map((brand, index) => (
                <li
                  key={index}
                  className={`cursor-pointer text-black text-xl px-2 py-1 text-center rounded ${
                    activeTab === index ? 'active bg-crimson-200' : ''
                  }`}
                  onClick={() => handleTabClick(index)}
                >
                  {brand.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-1/2 lg:w-3/4 px-5">
          {brandsData.map((brand, index) => (
            <div
              key={index}
              className={`tab-content group1 ${activeTab === index ? '' : 'hidden'}`}
            >
              <h2 className="text-2xl font-bold mb-4">{brand.name} we use</h2>
              <div className="absolute hidden lg:flex top-4 right-4">
                <PreviousButton onClick={handlePreviousClick} />
                <NextButton onClick={handleNextClick} />
              </div>

              <div className="flex flex-wrap">
                {brand.brands.map((item, idx) => (
                  <div key={idx} className="w-full lg:w-1/4 p-2">
                    <img className="mx-auto   " src={item.imgSrc} alt={item.imgAlt} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MaterialsTab;
