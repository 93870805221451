import './App.css';
import Routes from './Routes';
function App() {
  return (
    <div className='font-bfont'>
    <Routes />
    </div>
  );
}

export default App;
